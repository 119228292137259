import dayjs from 'dayjs'
import {
  Text,
  Lockup,
  IconQuality,
  rem,
  IconFlexible,
  IconValue,
} from '@butcherbox/freezer'
import type { ShowToast } from 'design/contexts/Toast/Toast.context'
import { makeSafeVariants } from 'design/image-utils'
import { IBackgroundImageProps } from 'gatsby-background-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React from 'react'
import { useProductOfferLander } from '~/cms/hooks/useProductOfferLander'
import useProtectedPage from '~/cms/hooks/useProtectedPage'
import makeRichTextOptions from '~/cms/rich-text'
import { CMS } from '~/cms/types'
import ModuleMemberPerks, {
  sharedIconProps,
} from '~/components/ModuleMemberPerks/ModuleMemberPerks'
import ModuleOptIn from '~/components/ModuleOptIn/ModuleOptIn'
import ModuleSocialCards from '~/components/ModuleSocialCards/ModuleSocialCards'
import ModuleText from '~/components/ModuleText/ModuleText'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import setLanderCookies from '~/utils/lander'

/**
 * TODO: Encapsulate this within `ModuleOptIn`?
 * from VariationProductOfferLander
 */
const FORM_SECTION_HEIGHT = 576

export default function GenericReferralLander({
  uniquePageId,
  pageData,
  memberPerksImage,
}: {
  uniquePageId: string
  pageData: CMS.ProtectedPage<CMS.ProductOfferLander>
  memberPerksImage: IBackgroundImageProps['fluid']
}) {
  const {
    content: {
      coupon,
      offer,
      moduleOptInBottom,
      moduleOptInTop,
      fullWidthTextModule,
      socialCards,
    },
  } = useProtectedPage(pageData, useProductOfferLander)

  const formProps = {
    destinationToast: [
      'success',
      { children: `Your $50 off has been applied!` },
    ] as Parameters<ShowToast>,
  }

  React.useEffect(() => {
    setLanderCookies({
      lander: uniquePageId,
      couponCode: coupon.code,
      offer: offer.id
        ? {
            id: offer.id,
            expiry: dayjs(offer.expiration).endOf('day'),
          }
        : undefined,
    })
  }, [uniquePageId, coupon, offer])

  return (
    <UnauthenticatedLayout disableHeader>
      <ModuleOptIn
        backgroundColor="bb.ivory"
        body={moduleOptInTop.body}
        cta={moduleOptInTop?.cta}
        dataWhere="optin_top"
        disclaimers={moduleOptInTop.disclaimers}
        formProps={formProps}
        header={moduleOptInTop.header}
        headerColor="slate"
        heading="h1"
        id="get-started-optin"
        imgAlt={moduleOptInTop?.imageAlt}
        imgOverlayBackground={makeSafeVariants({
          imageMobile: moduleOptInTop?.imageOverlayBackgroundMobile,
          imageDesktop: moduleOptInTop?.imageOverlayBackgroundDesktop,
        })}
        shouldCollectEmail={true}
        variant="Card Overlay"
      />

      {fullWidthTextModule.header || fullWidthTextModule.body?.raw ? (
        <ModuleText aria-labelledby="get-started-promo">
          <Lockup textAlign="center">
            <IconQuality display="inlineBlock" size="regular" variant="duo" />
            {fullWidthTextModule.header ? (
              <Text component="h2" id="get-started-promo" variant="Subhead1">
                {fullWidthTextModule.header}
              </Text>
            ) : null}
            {fullWidthTextModule.body?.raw ? (
              <Text variant="Body1Regular">
                {renderRichText(
                  fullWidthTextModule.body,
                  makeRichTextOptions()
                )}
              </Text>
            ) : null}
          </Lockup>
        </ModuleText>
      ) : null}

      <ModuleMemberPerks
        as="section"
        bgFluid={memberPerksImage}
        header="What is ButcherBox?"
        id="get-started-member-perks"
        perks={[
          {
            renderIcon: () => <IconValue {...sharedIconProps} />,

            header: 'unbeatable value',
            body:
              'Get a range of high-quality cuts, from ground beef to filet mignon, at an amazing value.',
          },
          {
            renderIcon: () => <IconFlexible {...sharedIconProps} />,

            header: 'complete flexibility',
            body:
              'Shipping is always FREE, we deliver to your door on your schedule, and you can cancel anytime.',
          },
          {
            renderIcon: () => <IconQuality {...sharedIconProps} />,
            header: 'high-quality meat',
            body:
              '100% grass-fed beef, free-range organic chicken, humanely raised pork, and wild-caught seafood.',
          },
        ]}
        subheader="A choice you can believe in."
      />

      <ModuleOptIn
        backgroundColor="white"
        body={moduleOptInBottom.body}
        cta={moduleOptInBottom?.cta}
        dataWhere="optin_bottom"
        disclaimers={moduleOptInBottom.disclaimers}
        formProps={formProps}
        header={moduleOptInBottom.header}
        headerColor="slate"
        heading="h2"
        height={{ base: 'auto', tablet: rem(FORM_SECTION_HEIGHT) }}
        id="get-started-optin-card"
        img={moduleOptInBottom?.imageSplitCard?.fluid}
        imgAlt={moduleOptInBottom.imageAlt}
        shouldCollectEmail={true}
        variant="Card"
      />

      <ModuleSocialCards
        as="section"
        cards={socialCards}
        id="get-started-social-proof"
      />
    </UnauthenticatedLayout>
  )
}
