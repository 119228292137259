import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { CMS } from '~/cms/types'
import GenericReferralLander from '~/routes/Referral/GenericReferralLander'
import { cleanJsonStrings } from '~/utils/unicode'

export default function Friend() {
  const { pageData, memberPerksImage } = cleanJsonStrings(
    useStaticQuery<{
      pageData: CMS.ProtectedPage<CMS.ProductOfferLander>
      memberPerksImage: {
        childImageSharp: { fluid: any }
      }
    }>(graphql`
      query {
        pageData: contentfulProtectedPage(pageId: { eq: "referral-lander" }) {
          ...ProtectedPage
        }

        memberPerksImage: file(
          relativePath: { eq: "steak_on_serving_board_crop_center.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1441, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  )

  return (
    <GenericReferralLander
      memberPerksImage={memberPerksImage?.childImageSharp?.fluid || {}}
      pageData={pageData}
      uniquePageId="refer-friend"
    />
  )
}
